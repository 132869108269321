import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { about } from '../../utils/tempJson/_footer';
import logo from '../../assets/._4.png'
import { useSelector } from 'react-redux';
import FooterIcon from '../../assets/Startup-India_Preview.png'

function Footer() {
  const { isDark } = useSelector((state) => state.themeState)
  return (
    <div className={`${!isDark ? 'bg-blue-100 ' : 'bg-slate-900 text-white'}`}>
      {/* <div className='flex flex-col md:flex-row gap-16 py-5 px-7'> */}
      <div className='grid grid-cols-2 md:flex md:flex-row justify-between py-2 me-10'>
        <ul className='flex flex-col me-10'>
          <img src={logo} alt='logo' className='flex justify-center ml-5 md:ml-0 items-center my-2 w-32' />
          {/* <p className='ml-4'>address</p> */}
          <li className='mt-2 ml-4 cursor-pointer'>Site feedback</li>
          <li className='mt-2 ml-4 cursor-pointer'>Contact Us</li>
        </ul>
        <ul className='ml-14'>
          <h1 className='my-2 font-bold'>About Inkqubee</h1>
          {about.map((items, index) => (
            <li className='mt-2 cursor-pointer' key={index}>
              {items.links}
            </li>
          ))}
        </ul>
        {/* </div> */}

        <ul className='me-10'>
          <h1 className='my-2 font-bold'>Buyers</h1>
          <li className='mt-2 cursor-pointer'>Browse all directories</li>
          <li className='mt-2 cursor-pointer'>Review Service Providers</li>
          <li className='mt-2 cursor-pointer'>Blog & industry Surveys</li>
          <li className='mt-2 cursor-pointer'>Smart Match</li>
          <li className='mt-2 cursor-pointer'>Buyer FAQs</li>
        </ul>
        <ul className='ml-11 mt-1 md:mt-0'>
          <h1 className='my-2 font-bold'>Service providers</h1>
          <li className='mt-2 cursor-pointer'>Service Provider Guide</li>
          <li className='mt-2 cursor-pointer'>Get listed</li>
          <li className='mt-2 cursor-pointer'>Advertise</li>
          <li className='mt-2 cursor-pointer'>Marketing Opertunites</li>
          <li className='mt-2 cursor-pointer'>Service Providers FAQs</li>
        </ul>
        <div className='p-2 ml-14'>
          <img src={FooterIcon} alt='startup-registerd' className='sm:justify-center h-52 md:w-52' />
        </div>
      </div>

      <div className='flex flex-col md:flex-row justify-between border-t-2 border-blue-200'>
        <ul className='flex flex-col md:flex-row justify-center px-5 py-5 gap-6'>
          <a href='#' className='cursor-pointer hover:underline'>Privacy policy</a>
          <Link to={"/terms-and-service"} className='cursor-pointer hover:underline'>Terms of service</Link>
          <li>&copy; 2024 Inkqubee. All rights reserved.</li>
        </ul>
        <div className='flex justify-center list-none px-5 py-5 mr-8 gap-3'>
          <li className='cursor-pointer'><FaInstagram size={30} /></li>
          <li className='cursor-pointer'><FaLinkedin size={30} /></li>
          <li className='cursor-pointer'><FaYoutube size={30} /></li>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Footer) 
