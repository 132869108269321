import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_SITE_KEY } from '../../../utils/constant';
import { useSelector } from 'react-redux';
import { Alert, message } from 'antd';
import { useRegisterForm } from '../../../helper/auth/authFormHook';
import { signupUser } from '../../../helper/auth/authRequestCall';

function Register() {
  const { isDark } = useSelector((state) => state.themeState)
  const [registered, setRegistered] = useState(false)
  const reCaptcha = useRef(null)

  const handleSubmit = (values) => {
    const fullName = values.fullName
    const email = values.email
    const password = values.password
    const captcha = values.captcha

    signupUser(fullName, email, password)
      .then((data) => {
        if (data && data.status === 201) {
          formik.resetForm()
          if (reCaptcha.current) {
            reCaptcha.current.reset()
          }
          setRegistered(true)
          console.log(data)
        }else{
          message.error("registration failed")
        }
      })
      .catch((err) => {
        console.error("error", err)
      })

  }

  const formik = useRegisterForm(handleSubmit)

  const onCaptchaChange = (value) => {
    formik.setFieldValue('captcha', value)
  };

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen ${!isDark ? 'bg-blue-950' : 'dark-mode'} p-4`}>
      {
        registered &&
        <Alert
          message="Registered successfully"
          description="please check your mail and activate your account.."
          className='mt-20'
          type='success'
          closable
        />
      }
      <div className={`w-full justify-center max-w-sm p-8 space-y-6 ${!isDark ? 'bg-blue-500' : 'bg-slate-500 '} rounded mt-9`}>
        <h1 className='text-black text-2xl font-bold text-center mb-4'>Register</h1>
        <form className='flex flex-col px-5' onSubmit={formik.handleSubmit}>
          <input
            type='text'
            placeholder='enter your name'
            id='fullName'
            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.fullName && formik.errors.fullName ? 'border-2 border-red-600' : ' border-gray-400'}`}
            onChange={formik.handleChange}
            value={formik.values.fullName}
          />
          {
            formik.touched.userName && formik.errors.fullName ?
              <div className='text-red-600 ml-1'>
                {formik.errors.fullName}
              </div> : null
          }
          <input
            type='email'
            id='email'
            placeholder='enter your email'
            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.email && formik.errors.email ? 'border-2 border-red-600' : ' border-gray-400'}`}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {
            formik.touched.email && formik.errors.email ?
              <div className='text-red-600 ml-1'>
                {formik.errors.email}
              </div> : null
          }
          <input
            type='password'
            id='password'
            placeholder='enter your password'
            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.password && formik.errors.password ? 'border-2 border-red-600' : ' border-gray-400'}`}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {
            formik.touched.password && formik.errors.password ?
              <div className='text-red-600 ml-1'>
                {formik.errors.password}
              </div> : null
          }
          <div className='justify-center mt-2 h-16 transform scale-90 origin-top-left'>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={reCaptcha}
              onChange={(value) => {
                onCaptchaChange(value)
              }}
            />

          </div>
          {
            formik.touched.captcha && formik.errors.captcha ?
              <div className='text-red-600 ml-1 mt-1'>
                {formik.errors.captcha}
              </div> : null
          }
          <p className='px-2 mt-2'>Want to SignIn?<Link to='/login' className='text-black font-bold underline'> SignIn</Link></p>

          <input
            type='submit'
            value="SignUp"
            className='btn max-w-md mt-2 w-52'
          />
        </form>
      </div>
    </div>
  )
}

export default Register
