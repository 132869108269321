import React from 'react'
import { useSelector } from 'react-redux'
import abcCompany from '../../../assets/abcComp.jpg'
import { CiLocationOn } from "react-icons/ci";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { CiFlag1 } from "react-icons/ci";
import RatingStar from '../../../utils/RatingStar';
import { Link } from 'react-router-dom';
import { SiWebmoney } from "react-icons/si";
import { ImProfile } from "react-icons/im";
import { service_providers } from '../../../utils/tempJson/_tempItems';



function ServiceProviderList() {
  const { isDark } = useSelector((state) => state.themeState)
  const rating = 4
  const totalStars = 5
  return (
    <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10 px-3`}>
      <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-slate-100'} h-full w-full p-6 rounded space-y-4`}>
        <div className='border-b border-gray-500'>
          <h1 className='text-2xl font-bold'>Top Service Providers</h1>
          <div className='p-2'>
            <p className='text-gray-500'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
        {service_providers.map((item, id) => (
          <div className='h-full w-full bg-white rounded-lg mt-4 border border-gray-400 shadow' key={id}>
            <div className='p-3' >
              <div className='flex justify-between'>
                <div className='flex'>
                  <img src={item.logo} alt={item.name} className='h-12 rounded-full me-2' />
                  <div>
                    <h1 className='mt-2 text-xl font-bold cursor-pointer hover:underline'>{item.name}</h1>
                    <div className='flex '>
                      <span>4.1</span>
                      <div className='flex mt-1'>
                        {Array.from({ length: totalStars }, (_, index) => (
                          <RatingStar
                            key={index}
                            filled={index < rating}
                          />              
                        ))}
                      </div>
                      <span className='underline text-blue-600 text-sm mt-1 cursor-pointer'>20 Reviews</span>
                    </div>
                  </div>
                </div>
                <div className='flex mt-3'>
                  <button className='flex bg-blue-600 p-2 text-white rounded'>Visit Website <SiWebmoney className='mt-1 ml-1' /></button>
                  <button className='flex ml-4 border border-blue-800 p-2 rounded'>View Profile <ImProfile className='mt-1 ml-1' /></button>
                </div>
              </div>
              <div className='flex gap-12 px-4 mt-2 py-3'>
                <div>
                  <li className='flex text-gray-500'><CiLocationOn className='mt-1 me-1' /> {item.location}</li>
                  <li className='flex text-gray-500'><FaUsersBetweenLines className='mt-1 me-1' /> {item.employees}</li>
                  <li className='flex text-gray-500'><CiFlag1 className='mt-1 me-1' /> founded,{item.founded}</li>
                </div>
                <div className='p-2'>
                  <h1 className='text-blue-500'>Service Provided</h1>
                  <ul>
                    <li>60% Mobile App development</li>
                    <li>50% Web development</li>
                    <li>70% Marketing</li>
                    <Link to={'/service-provider/profile/view'}><li className='text-blue-600'>...Read More</li></Link>
                  </ul>
                </div>
                {
                  item.projectHighlight.map((project, index) => (
                    <div className='' key={index}>
                      <h1>PROJECT HIGLIGHT</h1>
                      <div>
                        <h1 className='font-bold text-md'>{project.title}</h1>
                        <p className='text-gray-500'>{project.content}....<Link>Read more</Link></p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ServiceProviderList