import { SiGooglemarketingplatform } from "react-icons/si";
import marketing from '../../assets/marketing.svg'
import design from '../../assets/design.svg'
import tech from '../../assets/tech.svg'
import portfolio1 from '../../assets/portfolio1.jpg'
import portfolio2 from '../../assets/portfolio2.webp'
import organization from '../../assets/._4.png'
import GoogleImg from '../../assets/google.jpg'
import abc from '../../assets/abcComp.jpg'
import abcCompany from '../../assets/abcComp.jpg'
import cba from '../../assets/cba.jpg'
import someCompany from '../../assets/some company.jpg'


export const inkqubee_acadamy = 
    [
        {
            name:"Marketing",
            icon:marketing,
            path:"/marketing"
        },
        {
            name:"Design",
            icon:design,
            path:"/design"
        },
        {
            name:"Tech-and-Data",
            icon:tech,
            path:"/tech"
        }
        // {
        //     name:"Product",
        //     icon:product,
        //     path:"/Product"
        // },
        // {
        //     name:"Buisness",
        //     icon:buisness,
        //     path:"/buisness"
        // },
    ]

    

    export const sp_portfolio_items=[
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"software development",
            image:portfolio2,
            detail:'we have developed the software'
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
        {
            title:"google cloud",
            image:portfolio1,
            detail:"We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
        },
    ]

   export const chart_data=[
        { "name": "Jan", "data1": 200, "data2": 400 },
        { "name": "Feb", "data1": 300, "data2": 500 },
        { "name": "Mar", "data1": 250, "data2": 450 },
        { "name": "Apr", "data1": 320, "data2": 480 },
        { "name": "May", "data1": 210, "data2": 470 },
        { "name": "Jun", "data1": 290, "data2": 520 },
        { "name": "Jul", "data1": 340, "data2": 530 },
        { "name": "Aug", "data1": 280, "data2": 510 },
        { "name": "Sep", "data1": 260, "data2": 490 },
        { "name": "Oct", "data1": 300, "data2": 550 },
        { "name": "Nov", "data1": 330, "data2": 560 },
        { "name": "Dec", "data1": 310, "data2": 600 }
      ]

export const company_list = [
    {
        logo:organization,
        name:"inkqubee",
        noOfEmp:100,
        mailId:"comp@gmail.com",
        founded:'Founded,2001',
        services:"Software Development",
        location:"Banglore",
        verified:true,
        charts:[
            { "name": "Jan", "data1": 300, "data2": 100 },
            { "name": "Feb", "data1": 500, "data2": 500 },
            { "name": "Mar", "data1": 250, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 400 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 100, "data2": 330 },
            { "name": "Aug", "data1": 280, "data2": 510 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }
        ]
    },
    {
        logo:GoogleImg,
        name:"Google",
        noOfEmp:10000,
        mailId:"google@gmail.com",
        founded:'Founded,2000',
        services:"Advertising&Marketing",
        location:"India",
        verified:true,
        charts:[
            { "name": "Jan", "data1": 100, "data2": 300 },
            { "name": "Feb", "data1": 100, "data2": 100 },
            { "name": "Mar", "data1": 250, "data2": 150 },
            { "name": "Apr", "data1": 320, "data2": 300 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 220 },
            { "name": "Jul", "data1": 200, "data2": 430 },
            { "name": "Aug", "data1": 280, "data2": 210 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 400, "data2": 550 },
            { "name": "Nov", "data1": 630, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 100 }
        ]
    },
    {
        logo:GoogleImg,
        name:"TCS",
        noOfEmp:1000,
        mailId:"tcs@gmail.com",
        founded:'Founded,2000',
        services:"Advertising&Marketing",
        location:"India",
        verified:false,
        charts:[
            { "name": "Jan", "data1": 200, "data2": 400 },
            { "name": "Feb", "data1": 300, "data2": 500 },
            { "name": "Mar", "data1": 250, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 480 },
            { "name": "May", "data1": 210, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 340, "data2": 530 },
            { "name": "Aug", "data1": 280, "data2": 510 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }
          ]
    },
    {
        logo:abc,
        name:"Abc",
        noOfEmp:1001,
        mailId:"tcs@gmail.com",
        founded:'Founded,2000',
        services:"Advertising&Marketing",
        location:"India",
        verified:true,
        charts:[
            { "name": "Jan", "data1": 400, "data2": 400 },
            { "name": "Feb", "data1": 500, "data2": 500 },
            { "name": "Mar", "data1": 550, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 400 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 100, "data2": 330 },
            { "name": "Aug", "data1": 280, "data2": 610 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }
        
          ]
    }
]

export const service_providers=[
    {
        id:1,
        logo:abcCompany,
        name:"abc",
        location:"banglore",
        employees:"10-50",
        founded:2020,
        projectHighlight:[
            {
                title:"Mobile App Dev for WellTech Product Company",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id:2,
        logo:cba,
        name:"cba",
        location:"chennai",
        employees:"100-500",
        founded:2010,
        projectHighlight:[
            {
                title:"web Dev for WellTech Product Company",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id:3,
        logo:someCompany,
        name:"some company",
        location:"Hydrabad",
        employees:"100-200",
        founded:2018,
        projectHighlight:[
            {
                title:"software Developed for WellTech Product Company",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    }
]
