import * as Yup from 'yup'

export const orgRegsiterSchema=Yup.object().shape({
    CIN:Yup.string().required("CIN Required"),
    PAN:Yup.string().required("PAN Required"),
    legalEntityName:Yup.string().required("legalEntityName Required"),
    officialMailId:Yup.string().email("Invalid email").required("Email required"),
    verifyMailId:Yup.string().oneOf([Yup.ref('officialMailId'),null],"Email doesn't match").required("Email required"),
    companyBrandName:Yup.string().required("company or BrandName required"),
    location:Yup.string().required("location required"),
    noOfEmployees:Yup.string().required("mention how many emplotyees are there")
})

export const signUpSchema=Yup.object().shape({
    fullName:Yup.string()
    .min(2,"Too short")
    .max(50,"Too long")
    .required("name required"),
    email:Yup.string()
    .email("Invalid email")
    .required("Email required"),
    password:Yup.string()
    .required("Secure your account with password!"),
    captcha: Yup.string().required('Captcha validation is required')
})

//login validation
export const loginSchema=Yup.object().shape({
    userName:Yup.string()
    .email("invalid email")
    .required("email is required"),
    password:Yup.string()
    .required("Password required")
})

export const forgotPasswordSchema=Yup.object().shape({
    email:Yup.string()
    .email("invalid email")
    .required("email is required"),
    newPassword:Yup.string()
    .required("Password required")
})