import React from 'react'
import { useSelector } from 'react-redux'

function ContactInfo() {
  const { isDark } = useSelector((state) => state.themeState)
  return (
    <div className='h-screen'>
      <div className={`flex ${isDark ? 'bg-black' : 'bg-white'} h-full justify-center items-center`}>
        <h1>Your Contact info</h1>
      </div>
    </div>
  )
}

export default ContactInfo