import React from 'react'
import { useSelector } from 'react-redux'

function TermsAndService({fontSize}) {
  const { isDark } = useSelector((state) => state.themeState)
  return (
    <div className='min-h-screen flex flex-col'>
      <main className='flex-grow'>
        <div className='pt-16'>
          <div className={`w-full p-4  ${!isDark ? 'bg-blue-900 text-white' : 'bg-slate-500 text-white'}`}>
            <h1 className='text-2xl mt-2'>Terms & Services</h1>
          </div>
          <section className={`flex flex-row w-full justify-between p-8 h-screen ${!isDark? 'bg-white':'dark-mode'}`}>
          <p className='' style={{fontSize:`${fontSize+6}px`}}>content</p>
          </section>
        </div>
      </main>
    </div>
  )
}

export default TermsAndService
