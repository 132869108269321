import React, { useState } from 'react'
import { useSelector } from 'react-redux'

function Services() {
    const { isDark } = useSelector((state) => state.themeState)
    const [selectedItems, setSelectedItems] = useState([]);
    const checkbox_items = ['Web Development', 'Financing', 'Marketing']

    const handleCheckboxChange = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };
    return (
        <div className={`flex flex-col justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3`}>
            <div className={`flex ${isDark ? 'bg-black' : 'bg-white'} h-50 justify-center items-center`}>
                <div className='px-4 w-48'>
                    {/* <div className="flex flex-wrap mb-4 p-2 space-x-2 ">
                        {selectedItems.map((item, index) => (
                            <span key={index} className=" bg-slate-600 text-white rounded-full">
                                {item}
                            </span>
                        ))}
                    </div> */}
                    <input
                        type='text'
                        placeholder='Search Services'
                        className='focus:outline-none border border-gray-600 rounded-full p-1 mt-2 text-black'
                    />
                    {checkbox_items.map((item, index) => (
                        <ul className='mt-1 p-1' key={index}>
                            <li>
                                <label>
                                    <input type='checkbox'
                                        className='me-1'
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    {item}
                                </label>
                            </li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Services