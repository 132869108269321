import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import avatar from '../../assets/userAvatar.jpg'
import { DialogContentText } from '@mui/material';
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../helper/auth/authRequestCall';
import { jwtDecode } from 'jwt-decode';
import { setUserDetails } from '../../store/slices/authSlice';

function UserProfile({openProfile,handleCloseProfile,editProfile,setEditProfile}) {
    const {user}=useSelector((state)=>state.authenticationState)
    const dispatch=useDispatch()

    const getUser=()=>{
          const token = localStorage.getItem('authToken')
          const decoded=jwtDecode(token)
          const userId=decoded.userId
          getUserProfile(userId)
          .then(data=>{
           const userProfile = data.data
           dispatch(setUserDetails(userProfile))
          })
          .catch(err =>{
            console.error(err)
          })
    }

    useEffect(()=>{
       getUser()
    })

    return (
        <Dialog open={openProfile} onClose={handleCloseProfile} className=''>
            <DialogTitle className='flex justify-between text-center'>user <button onClick={()=>handleCloseProfile()}><IoMdClose className='mt-1'/></button></DialogTitle>
            <DialogContent>
               {!editProfile ? 
              <><img src={avatar} alt="user" className='h-32 w-32 rounded-full ml-16 mt-2'/>
                <DialogContent>
                    <DialogContentText className='text-black text-center'>username: {user && user.fullName}</DialogContentText>
                    <DialogContentText className='text-center'>email: {user && user.username}</DialogContentText>
                    {/* <DialogContentText className='text-center'>Phone: 1234567890</DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <button className='flex bg-blue-600 p-1 text-white rounded-md'
                    onClick={()=>setEditProfile(!editProfile)}
                    >
                        Edit Info <MdEdit size={20} color='white' className='ml-2'/>
                    </button>
                </DialogActions></> 
                :
               <><img src={avatar} alt="user" className='h-32 w-32 rounded-full ml-16 mt-2'/>
                <DialogContent className='flex flex-col'>
                    <input
                    type='email'
                    value="someone@gmail.com"
                    className='p-2 border-2 border-gray-500 focus:outline-none'
                    />
                    <input
                    type='text'
                    value="someone"
                    className='p-2 mt-2 border-2 border-gray-500 focus:outline-none'
                    />
                    <input
                    type='number'
                    value="1234567890"
                    className='p-2 mt-2 border-2 border-gray-500 focus:outline-none'
                    />
                </DialogContent>
                <DialogActions>
                    <button className='flex bg-blue-600 p-1 text-white'
                    onClick={()=>setEditProfile(!editProfile)}
                    >
                        Submit
                    </button>
                </DialogActions></> }
            </DialogContent>
        </Dialog>
    )
}

export default UserProfile