import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdFilterListAlt } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import Services from './private/Services';
import { useLocation } from 'react-router-dom';
import { CiLocationOn } from "react-icons/ci";
import { sidebarToggle ,filterTogle} from '../store/slices/sidebarSlice';

function FilterBar() {
    const { isDark } = useSelector((state) => state.themeState)
    const { isFilter } = useSelector((state) => state.sidebarState)
    const location = useLocation()
    const[filterService,setFilterService]=useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        if (location.pathname === "/service-provider/list") {
            dispatch(filterTogle(true))
            dispatch(sidebarToggle(false))
          }
    },[location,dispatch])
    return (
        <aside className={`${isFilter ? 'hidden':'block'} sticky top-0 w-full max-w-64 h-screen ${isDark ? 'bg-slate-900' : 'bg-blue-950'} border-r-2 border-gray-500`}>
            <div className='flex flex-col justify-between mt-6'>
                <h1 className='flex mt-9 text-white text-lg'><MdFilterListAlt className='mt-1 me-1' color='white' size={20}/> Filter</h1>
                <div className='relative mt-1 p-2'>
                    <CiLocationOn className='absolute mt-1 mb-1 left-3 ml-2' size={20} color='gray'/>
                    <input
                    type='text'
                    placeholder='Filter by location'
                    className='p-1 pl-8 rounded-full focus:outline-none'
                    />
                </div>
                <ul className='space-y-5 p-3'>
                    <li 
                    className='flex justify-between text-xl text-white border-b-2 border-gray-300 cursor-pointer'
                    >Services 
                    {filterService ? 
                    <FaMinus
                    className='mt-1'
                    onClick={()=>setFilterService(!filterService)}
                    />
                    :
                    <FaPlus 
                    className='mt-1'
                    onClick={()=>setFilterService(!filterService)}
                    />
                    }
                    </li>
                    {filterService && 
                      <Services/>
                     }
                    <li
                    className='flex justify-between text-xl text-white border-b-2 border-gray-300 cursor-pointer'
                    >Industires <FaPlus className='mt-1'/></li>
                    <li
                    className='flex justify-between text-xl text-white border-b-2 border-gray-300 cursor-pointer'
                    >Review <FaPlus className='mt-1'/></li>
                    <li
                    className='flex justify-between text-xl text-white border-b-2 border-gray-300 cursor-pointer'
                    >Budget <FaPlus className='mt-1'/></li>
                </ul>
            </div>
        </aside>
    )
}

export default FilterBar