import React, { useRef, useState } from 'react'
import {  useSelector } from 'react-redux'
import { FaFileArrowUp } from "react-icons/fa6";
import { MdAddPhotoAlternate } from "react-icons/md";
import { message } from 'antd';
import { servicesOptions } from '../../../../utils/tempJson/_optionsJson';
import { useOrgForm } from '../../../../helper/organization/orgFormHook';
import { organizationRegister } from '../../../../helper/organization/orgRequestCall';


function RegisterDetail({ fontSize }) {
    const { isDark } = useSelector((state) => state.themeState)
    const [selectedFile, setSelectedfile] = useState(null)
    const [previewFile, setPreviewFile] = useState(null)
    const [selectedLogo, setSelectedlogo] = useState(null)
    const [previewlogo, setPreviewLogo] = useState([])

    const fileRef = useRef()
    const docRef = useRef()
    const handleSubmit = (values) => {
        const CIN_number = values.CIN.toLowerCase();
        const PAN_number = values.PAN.toLowerCase();
        const name = values.legalEntityName.toLowerCase()
        const official_mail = values.officialMailId.toLowerCase()
        const verify_MailId = values.verifyMailId.toLowerCase()
        const company_brandName = values.companyBrandName.toLowerCase()
        const org_location = values.location.toLowerCase()
        const No_of_employees = values.noOfEmployees.toLowerCase()
        const org_logo = values.logo
        const registered_document = values.registerdDocument
        const services_and_products = values.serviceAndProducts

        organizationRegister(
            name,
            company_brandName,
            CIN_number,
            PAN_number,
            org_location,
            official_mail,
            org_logo,
            No_of_employees,
            registered_document,
            services_and_products
        )

            .then((res) => {
                if (res.status === 201) {
                    message.success("Registered successfully please wait until verification done")
                    //console.log(data)
                    fileRef.current.value = null
                    docRef.current.value = null
                    console.log("status", res.status)
                    console.log("status")
                } else {
                    message.error("Error to process you request")
                }
                console.log("status", res.status)
                console.log("status")
            })
            .catch(err => {
                console.log(err)
            })
        console.log("selected", selectedFile)
        console.log("selected", selectedLogo)
    }

    const formik = useOrgForm(handleSubmit)

    const handleFileChange = (e) => {
        const fileList = e.target.files[0];
        console.log("Selected File:", fileList);
        if (fileList) {
            formik.setFieldValue('registerdDocument', fileList)
            setSelectedfile(fileList)

        }
    }

    const handleLogo = (e) => {
        const fileList = e.target.files;
        const fileArray = Array.from(fileList);
        const urlArray = fileArray.map((file) =>
            URL.createObjectURL(file)
        )
        if (fileArray.length > 2) {
            console.error("add one logo only")
            fileRef.current.value = null
        } else {
            formik.setFieldValue('logo', fileArray)
            setPreviewLogo(urlArray)
            setSelectedlogo(fileList)
        }
        console.log("logo is", fileList)
    }

    const handleRemoveLogo = () => {
        setSelectedlogo(null)
        setPreviewLogo(null)
        URL.revokeObjectURL(previewlogo)
    }

    const selectedChange = (e) => {
        console.log("value is", e.target.value)
        formik.setFieldValue("serviceAndProducts", e.target.value)
    }
    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`w-full max-w-4xl max-h-full justify-center ${!isDark ? 'bg-slate-100' : 'bg-slate-500 '} space-y-6 rounded-lg h-full mt-11`}>
                <form
                    className='flex flex-col space-y-8 px-5 py-8'
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className='text-black text-center py-2' style={{ fontSize: `${fontSize + 9}px` }}>Enter the details below</h1>
                    <div className='flex flex-col justify-between'>
                        <input
                            type='text'
                            id='CIN'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.CIN && formik.errors.CIN ? 'border-red-600' : ' border-gray-400 '}`}
                            placeholder='Enter CIN'
                            onChange={formik.handleChange}
                            value={formik.values.CIN}
                        />
                        {formik.touched.CIN && formik.errors.CIN ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.CIN}
                            </div> : null
                        }
                        <input
                            type='text'
                            id='PAN'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 mt-2 ${formik.touched.PAN && formik.errors.PAN ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='Enter PAN'
                            onChange={formik.handleChange}
                            value={formik.values.PAN}
                        />
                        {formik.touched.PAN && formik.errors.PAN ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.PAN}
                            </div> : null
                        }
                        <input
                            type='text'
                            id='legalEntityName'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.legalEntityName && formik.errors.legalEntityName ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='Enter Legal Entity Name'
                            onChange={formik.handleChange}
                            value={formik.values.legalEntityName}
                        />
                        {formik.touched.legalEntityName && formik.errors.legalEntityName ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.legalEntityName}
                            </div> : null
                        }
                        <input
                            type='email'
                            id='officialMailId'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.officialMailId && formik.errors.officialMailId ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='Enter Official mail id'
                            onChange={formik.handleChange}
                            value={formik.values.officialMailId}
                        />
                        {formik.touched.officialMailId && formik.errors.officialMailId ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.officialMailId}
                            </div> : null
                        }
                        <input
                            type='email'
                            id='verifyMailId'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.verifyMailId && formik.errors.verifyMailId ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='verify official mail id'
                            onChange={formik.handleChange}
                            value={formik.values.verifyMailId}
                        />
                        {
                            formik.touched.verifyMailId && formik.errors.verifyMailId ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.verifyMailId}
                                </div> : null
                        }
                        <input
                            type='text'
                            id='companyBrandName'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.companyBrandName && formik.errors.companyBrandName ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='Company/Brand name'
                            onChange={formik.handleChange}
                            value={formik.values.companyBrandName}
                        />
                        {
                            formik.touched.companyBrandName && formik.errors.companyBrandName ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.companyBrandName}
                                </div> : null
                        }
                        <input
                            type='text'
                            id='location'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.location && formik.errors.location ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='Location'
                            onChange={formik.handleChange}
                            value={formik.values.location}
                        />
                        {
                            formik.touched.companyBrandName && formik.errors.location ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.location}
                                </div> : null
                        }
                        <input
                            type='text'
                            id='noOfEmployees'
                            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.noOfEmployees && formik.errors.noOfEmployees ? 'border-red-600' : ' border-gray-400'}`}
                            placeholder='No of employees'
                            onChange={formik.handleChange}
                            value={formik.values.noOfEmployees}
                        />
                        {
                            formik.touched.companyBrandName && formik.errors.noOfEmployees ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.noOfEmployees}
                                </div> : null
                        }
                        <div className='flex gap-3 justify-center items-center'>
                            <div className='relative bg-white rounded mt-2 border-2 border-gray-400 w-full shadow-xl'>
                                <label className=' flex justify-center bg-white text-black items-center text-xl text-center py-2 px-4 rounded cursor-pointer border-gray-400'>Attach Company Registration document <FaFileArrowUp className='ml-2' /></label>
                                <input
                                    type='file'
                                    className='absolute inset-4 opacity-0 cursor-pointer border-gray-400'
                                    accept='application/pdf'
                                    onChange={handleFileChange}
                                    ref={docRef}
                                    required
                                />
                                {selectedFile && (
                                    <div className='flex flex-col items-center space-y-2'>
                                        <p className='text-black'>{selectedFile.name}</p>
                                        <div className="relative p-2">
                                            <a href={previewFile} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src='https://img.icons8.com/ios-filled/100/000000/pdf.png'
                                                    alt='pdf icon'
                                                    className='w-10 h-10'
                                                />
                                            </a>
                                            <button
                                                onClick={() => {
                                                    setSelectedfile(null)
                                                    setPreviewFile(null)
                                                    URL.revokeObjectURL(previewFile)
                                                }}
                                                className='absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='relative bg-white rounded justify-center border-2 border-gray-400 items-center mt-1 w-full shadow-xl'>
                                <label className=' flex justify-center bg-white text-black items-center text-xl text-center py-2 px-4 rounded cursor-pointer '>Add Company Logo<MdAddPhotoAlternate className='ml-2' /></label>
                                <input
                                    type='file'
                                    onChange={handleLogo}
                                    multiple
                                    ref={fileRef}
                                    accept='image/*'
                                    className='absolute inset-4 opacity-0 cursor-pointer '
                                />
                                {selectedLogo && (
                                    <div className='flex flex-col items-center space-y-2'>
                                        <p className='text-black'>{selectedLogo.name}</p>
                                        <div className="relative p-2">
                                            <img
                                                src={previewlogo}
                                                alt='company logo'
                                                className='w-32 h-10'
                                            />
                                            <button
                                                onClick={handleRemoveLogo}
                                                className='absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <select name='services/products'
                            onChange={selectedChange}
                            value={formik.values.serviceAndProducts}
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        >
                            {servicesOptions.map((option, index) => (
                                <option key={index} value={option.value} >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formik.values.serviceAndProducts === 'other' && (
                            <input
                                type='text'
                                className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg me-5 mt-2 w-full border-gray-400'
                                placeholder='Enter your services/products'

                            />
                        )}
                    </div>

                    <div className='flex justify-center items-center'>
                        <input
                            type='submit'
                            className='btn max-w-md w-52'
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterDetail