import React from 'react'
import { useSelector } from 'react-redux'
import { ImFilter } from "react-icons/im";
import { service_filter_Options } from '../../../utils/tempJson/_optionsJson';

function Reviews() {
  const { isDark } = useSelector((state) => state.themeState)
  
  return (
    <div className={`flex flex-col h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3`}>
      <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-slate-100'} h-full w-full p-2 rounded space-y-4`}>
        <div className='flex flex-row p-2'>
          <span className='mt-5'><ImFilter size={20} color={'black'}/></span>
          <select name='Relevance'

            className={`shadow border ml-2 ${isDark ? 'bg-slate-700' : 'bg-white'} rounded-full max-w-40 py-2 px-3 ${!isDark ? 'text-gray-600' : 'text-white'} leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400`}
          >
            {service_filter_Options.map((option, index) => (
              <option key={index} value={option.value} >
                {option.label}
              </option>
            ))}
          </select>
          <span className='text-blue-600 mt-4 ml-2 cursor-pointer hover:underline'>clear filter</span>
        </div>
        <div className={` ${isDark ? 'bg-black':'bg-white'} border h-full border-gray-500 w-full rounded-lg`}>
          <div className='p-2 border-b border-gray-600'>
            <h1 className={` ${isDark ? 'text-white' : 'text-black'} text-xl font-bold`}>Review1</h1>
          </div>
        </div>
        <div className={` ${isDark ? 'bg-black':'bg-white'} border h-full border-gray-500 w-full rounded-lg`}>
          <div className='p-2 border-b border-gray-600'>
            <h1 className={` ${isDark ? 'text-white' : 'text-black'} text-xl font-bold`}>Review2</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews