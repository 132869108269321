import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import logo from '../../assets/inkqubeeLogo.png'
import logo2 from '../../assets/._4.png'
import { FaSearch } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

function Hero({fontSize}) {
    const values = useMemo(() => ["Web Development", "Lead Generation", "Talent Aquisition"], [])
    const [currentIndex, setCurrentIndex] = useState(0)
    const { isDark } = useSelector((state) => state.themeState)
   
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % values.length)
      }, 2000)
      return () => clearInterval(intervalId)
    }, [values.length])
  return (
    <div className={`flex flex-col w-full md:flex-row h-full md:h-screen justify-between ${!isDark ? 'light-mode ' : 'dark-mode'} py-6 md:py-11`}>
          <div className='flex flex-col justify-center items-start px-0 mt-10 md:mt-0 mb-2 md:mb-0'>
            <div className='p-3'>
              <img src={!isDark ? logo2 : logo} alt='logo' className="max-w-56 me-12 md:w-96 h-32 md:ml-0 md:max-w-full ml-6" />
            </div>
            <div className='ml-0 mt-0 md:mt-6 md:text-left px-4 md:px-0'>
              <h1 className='text-2xl md:text-2xl pl-0 md:px-16 ml-0'>Reshaping possibility</h1>
            </div>
            <div className='pt-2 md:pt-10 px-4 md:px-0'>
              <p className='ml-0 md:px-16' style={{ fontSize: `${fontSize + 6}px` }}>
                We simplify life for individuals and enterprises.
              </p>
              <p className='md:px-16 mt-3' style={{ fontSize: `${fontSize + 6}px` }}>
                No magic.. just solutions to core problems that hold you<br className="hidden md:block" /> ​back.<br />
                With our support, you’ll excel and reach your full potential.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-start md:items-center p-4 md:me-14" >
            <div className="p-8 md:p-4 lg:p-6" style={{ maxWidth: '400px' }}>
              <p
                style={{
                  fontSize: `${fontSize + 20}px`,
                }}
                 
              >
                Hire the right <br/> {values[currentIndex]}  </p>
                <p style={{
                  fontSize: `${fontSize + 20}px`,  
                }}>company with confidence </p>
               {/* <p style={{
                  fontSize: `${fontSize + 25}px`
                }}></p> 
               */}
            </div>
            <div className='flex flex-col md:flex-row mt-4 ml-6 md:ml-12 my-3'>
              <div className='relative w-full md:w-auto'>
                <FaSearch className='absolute left-3 top-3 transform text-gray-800' />
                <input
                  type='text'
                  placeholder='Search...'
                  className='w-full md:w-48 pl-10 pr-4 py-2 border border-gray rounded-3xl focus:outline-none text-black placeholder-slate-900'
                />
              </div>
              <div className='relative mt-2 md:mt-0 md:ml-2 w-full md:w-auto'>
                <FaLocationDot className='absolute left-3 top-3 transform text-gray-800' />
                <input
                  type='text'
                  placeholder='Location'
                  className='w-full md:w-48 pl-10 pr-4 py-2 border border-gray rounded-3xl focus:outline-none text-black placeholder-slate-900'
                />
              </div>
              <button className={`${!isDark ? 'bg-black text-white' : 'bg-white text-black'}  mt-2 md:mt-0 ml-0 md:ml-2 p-2 rounded-full w-full md:w-auto`}>
                <span className="block md:hidden text-xl">Find</span>
                <FaSearch className="text-xl hidden md:block" />
              </button>
            </div>
          </div>
        </div>
  )
}

export default Hero