export const nav_menu_routes = [
    '/login', 
    '/register', 
    '/setup-odc-india', 
    '/terms-and-service',
    '/user/dashboard',
    '/service-provider/portfolio',
    '/user/profile',
    '/service-provider/user-reviews', 
   '/service-provider/create-portfolio',
   '/register/company-profile',
   '/register-company',
   '/register-company/company-detail',
   '/service-provider/register',
   '/register-company/input/more-detail',
   '/service-provider/list',
   '/register-company/details-confirm',
   '/comapany/profile/:id',
   '/login/forgot-password'
]

export const login_btn_routes = [
    '/login', 
    '/register', 
    '/login/forgot-password'
]