import { createSlice } from "@reduxjs/toolkit";

const authSlice=createSlice({
    name:'authentication',
    initialState:{
        authenthurized:true,
        user:{}
    },
    reducers:{
        authenticated(state,action){
                state.authenthurized=true
                //localStorage.setItem('isAuthenticated',state.authenthurized)
        },
        setUserDetails(state,action){
                state.user=action.payload
        }
    }
});
const {actions,reducer}=authSlice
export const{authenticated,setUserDetails}=actions
export default reducer