import { useFormik } from "formik"
import { orgRegsiterSchema } from "../../validation";

export const useOrgForm=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            CIN: "",
            PAN: "",
            legalEntityName: "",
            officialMailId: "",
            verifyMailId: "",
            companyBrandName: "",
            location: "",
            noOfEmployees: "",
            logo:null,
            registerdDocument:null,
            serviceAndProducts:""
        },
        validationSchema: orgRegsiterSchema,
        onSubmit: values => {
            console.log("Formik Values Before Submit:", values);
            handleSubmit(values)
        },
    })
}