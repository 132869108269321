import { createSlice } from "@reduxjs/toolkit";

const themeSlice=createSlice({
    name:'theme',
    initialState:{
        isDark:false
    },
    reducers:{
        darkModeSwitcher(state,action){
                state.isDark=!state.isDark
        }
    }
});
const {actions,reducer}=themeSlice
export const{darkModeSwitcher}=actions
export default reducer