import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { IoNotifications } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
// import {  sidebarToggle } from '../../slices/sidebarSlice';
import { private_routes } from '../../routes';
import { Badge, Tooltip } from 'antd';
import { RiMessage2Line } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { sidebarToggle } from '../../store/slices/sidebarSlice';

function PrivateHeader({ fontSize }) {
  const location = useLocation()
  const { isDark } = useSelector((state) => state.themeState)
  const { isSidebar ,isFilter } = useSelector((state) => state.sidebarState)
  const activeRoute = private_routes.find(route => route.path === location.pathname);
  const handleSidebar = () => {
    //dispatch(filterTogle(false))
    dispatch(sidebarToggle())
    console.log(isSidebar)
  }
  const dispatch = useDispatch()
  return (
    <header className={`flex flex-col w-full pt-16 mt-2 z-20 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <header className='fixed w-full z-50' style={{ fontSize: `${fontSize + 6}px` }}>
        <div className={`flex flex-row  lg:flex-row justify-between p-2 py-2 ${!isDark ? 'bg-white' : 'dark-mode'} px-2 border-b-[1px] border-gray-500/[0.3]`}>
          <div className='flex flex-row justify-between'>
            <div className='flex items-center '>
              {
                !isSidebar && location.pathname === '/service-provider/list' ? 
                <FaFilter 
                  size={30} 
                  onClick={handleSidebar} 
                  color={`${isDark ? 'white' : 'black'}`} 
                  className='ml-4 cursor-pointer' 
                  />:
                  <IoMdMenu 
                  size={30} 
                  onClick={handleSidebar} 
                  color={`${isDark ? 'white' : 'black'}`} 
                  className='ml-4 cursor-pointer' 
                  />
              }
              <div className='ml-5'>
                <h1 className='text-xl font-bold'>{activeRoute && activeRoute.name}</h1>
              </div>
            </div>
          </div>
          <div className={`lg:flex lg:items-center text-black`}>
            <span className='flex justify-between cursor-pointer'>
              <Tooltip placement='bottom' title='Support'>
                <Badge count={0} showZero className='me-4 mt-2'>
                  <RiMessage2Line color={`${isDark ? 'white' : 'black'}`} size={30} className='me-1 ' />
                </Badge>
              </Tooltip>
              <Tooltip placement='bottom' title='Notification'>
                <Badge count={10} className='me-4 mt-2'>
                  <IoNotifications color={`${isDark ? 'white' : 'black'}`} size={30} />
                </Badge>
              </Tooltip>
            </span>
          </div>

        </div>
      </header>
    </header>
  )
}

export default PrivateHeader