import { MdOutlineDashboard } from "react-icons/md";
import { GrNotes } from "react-icons/gr";
import { FaBuildingUser } from "react-icons/fa6";

export const serviceProviderMenu = [
    { type: 'title', title: 'Service Provider' },
    { type: 'item', name: 'Dashboard', to: '/user/dashboard', icon: <MdOutlineDashboard/> },
    { type: 'item', name: 'Other service providers', to: '/service-provider/list', icon: <FaBuildingUser/> },
    { type: 'item', name: 'Others Projects', to: '/project-list', icon: <GrNotes/> },
    // { type: 'item', name: 'Reviews', to: '/service-provider/user-reviews', icon: <FaStar/> },
  ];