import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

function CreatePortfolio({fontSize}) {
    const { isDark } = useSelector((state) => state.themeState)
  return (
    <Fragment>
    <div className={`min-h-screen flex flex-col justify-center w-full items-center p-8 ${!isDark? 'bg-white':'dark-mode'} bg-opacity-90`}>
        <div className={`w-full max-w-2xl justify-center ${!isDark? 'bg-blue-950':'bg-slate-500 '} space-y-6 rounded-lg mt-16`}>
            <h1 className='text-white text-center py-2' style={{fontSize:`${fontSize+9}px`}}>Enter the details below</h1>
            <form 
            className='flex flex-col space-y-4 px-5 py-3'
            onSubmit={{}}
            >
                <input
                    type='text'
                    className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                    placeholder='Enter title'
                />

                <textarea
                   className='p-3 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                   placeholder='Provide description'
                   rows='5'
                />
                <input
                type='file'
                className='max-w-md w-52 text-white'
                />
                <div className='flex justify-center items-center'>
                <input
                type='submit'
                className='btn max-w-md w-52'
                />
                </div>
            </form>
        </div>
    </div>
</Fragment>
  )
}

export default CreatePortfolio