import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa";

function CompanyRegistration() {
    const { isDark } = useSelector((state) => state.themeState)
    const {user}=useSelector((state)=>state.authenticationState)
    const navgate = useNavigate()
    return (
        <div className={`flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10`}>
            <div className={`w-full max-w-2xl justify-center space-y-6 rounded-lg px-6 py-6`}>
                <div className='flex flex-col justify-between'>
                   <button 
                    onClick={()=>navgate('/register-company/company-detail')}
                    className='flex justify-between bg-red-700 text-white p-5 cursor-pointer text-lg rounded mt-3 border-2 font-bold hover:shadow-xl hover:bg-white hover:text-red-700 hover:border-red-800'
                    >Register Your Organization<FaArrowRight className='mt-1 ml-2' />
                    </button>

                    <button 
                    className='flex justify-between bg-blue-500 text-white p-5 cursor-pointer text-lg rounded mt-3 border-2 font-bold hover:shadow-xl hover:bg-white hover:text-blue-500 hover:border-blue-800'
                    >Post your requirments as client<FaArrowRight className='mt-1 ml-2' />
                    </button>

                    <button 
                    className='flex justify-between bg-blue-500 text-white p-5 cursor-pointer text-lg rounded mt-3 border-2 font-bold hover:shadow-xl hover:bg-white hover:text-blue-500 hover:border-blue-800'
                    onClick={()=>navgate('/service-provider/register')}
                    >
                        Register as Service provider<FaArrowRight className='mt-1 ml-2' />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CompanyRegistration