import React from 'react'
import userAvatar from "../../../assets/userAvatar.jpg"
import ProfileDropdown from '../../../utils/dropdowns/ProfileDropdown'
import { useNavigate } from 'react-router-dom'
import UserProfile from '../../../utils/Modal/ProfileModal'
import { FaRegUser } from "react-icons/fa";

function Auth({
    auth,
    profileDropdown,
    profileItems,
    setOpenProfile,
    openProfile,
    handleDropDown,
    profileDropdownRef,
    setEditProfile,editProfile}) 
{

    const navigate=useNavigate()
    return (
        <div>
            {
            !auth ? (
            <button onClick={()=>navigate('/login')}  className='btn flex'>
                <FaRegUser className='mt-1 me-1' />SignIn</button>
            )
            :
            (
            <div className='relative' ref={profileDropdownRef}>
                <div className='flex items-center space-x-3 cursor-pointer' onClick={() => handleDropDown()}>
                    <img src={userAvatar} alt='avatar' className='h-10 w-10 rounded-full' />
                </div>
                {profileDropdown && <ProfileDropdown
                    profileDropdown={profileDropdown}
                    items={profileItems}
                    handleOpenProfile={() => setOpenProfile(!openProfile)}
                />}

                {
                    openProfile && <UserProfile
                        editProfile={editProfile}
                        setEditProfile={setEditProfile}
                        openProfile={openProfile}
                        handleCloseProfile={() => {
                            setOpenProfile(!openProfile)
                            setEditProfile(false)
                        }
                        }
                    />
                }
            </div>
            )}
        </div>
    )
}

export default Auth