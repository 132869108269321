import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IoAdd } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { FaFontAwesomeFlag } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";
import { MdVerified } from "react-icons/md";
import { Tooltip } from 'antd';
import { CiEdit } from "react-icons/ci";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa6";
import Reviews from '../../../../components/private/organization/Reviews';
import { FaChevronUp } from "react-icons/fa6";
import YourVerification from '../../../../components/private/organization/YourVerification';
import Portfolio from '../../../../components/private/organization/Portfolio';
import ContactInfo from '../../../../components/private/organization/ContactInfo';
import { Base_url } from '../../../../utils/constant';
import verificationImg from '../../../../assets/verification.png'
import { GoUnverified } from "react-icons/go";
import { companyProfile } from '../../../../helper/organization/orgRequestCall';

function CompanyProfile() {
    const { isDark } = useSelector((state) => state.themeState)
    const navigate = useNavigate()
    const{org_id}=useParams()
    const [openReview, setOpenReview] = useState(false)
    const [openVerification, setOpenVerification] = useState(false)
    const [openPortfolio, setOpenPortfolio] = useState(false)
    const [openContact, setOpenContact] = useState(false)
    const[profile,setProfile]=useState(null)
    
    const getCompanyProfile=()=>{
        companyProfile(org_id)
        .then((res)=>{
            if(res.status===200){
              setProfile(res.data)
            }
        })
        .catch((err)=>{
            console.error(err)
        })
    }

    useEffect(()=>{
       getCompanyProfile()
    },[])
    console.log("id is",org_id)
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            {profile&&<div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-slate-100' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
                <div className='flex justify-between w-full mt-1'>
                    <div></div>
                    <button
                        className='flex bg-blue-500 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                        onClick={() => navigate('/register-company/input/more-detail')}
                    >
                        Add More Details <IoAdd className='mt-1 ml-2' color={'white'} />
                    </button>
                </div>
                <div className='flex justify-between max-w-7xl  p-2 relative'>
                    <img
                        src={profile.verified ? `${Base_url}/${profile.logo}`:verificationImg}
                        alt='logo'
                        className='relative h-32 w-44 rounded-xl shadow-lg'
                    />
                   {
                   profile.verified ?  
                    <Tooltip placement='top' title='verified'>
                        <MdVerified color='green' size={30} className='absolute top-0 left-44 cursor-pointer' />
                    </Tooltip>:
                    <Tooltip placement='top' title='under verification'>
                        <GoUnverified color='yellow' size={30} className='absolute top-0 left-44 cursor-pointer' />
                    </Tooltip>
                    }

                    <div className='flex flex-row gap-3'>
                        <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                            <h1 className='text-black text-xl'>Total Reviews</h1>
                            <span className='mt-2 text-blue-600'>100</span>
                        </div>

                        <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                            <h1 className='text-black text-xl'>Most Common Project Size </h1>
                            <span className='mt-2 text-blue-600'>$XX,XXX – $XXX,XXX</span>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-7 p-2 h-full '>
                    <div className={`flex flex-row justify-between gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full h-96 rounded-lg p-2`}>
                        <div className='p-2'>
                            <h1 className='flex text-2xl font-bold'><FcAbout className='mt-2 me-2' />about</h1>
                            <p className={`${isDark ? 'text-white' : 'text-gray-500'}`}>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div className='border-l p-2 px-6 max-w-96'>
                            <div className='flex justify-between'>
                                <div></div>
                                <Tooltip placement='top' title='Edit info'>
                                    <CiEdit size={20} className='hover:bg-slate-500 hover:text-white rounded-md cursor-pointer' />
                                </Tooltip>
                            </div>
                            <span>{profile.name}</span>
                            <Tooltip placement='top' title="Location" >
                                <span className='flex text-gray-600 mt-1'>
                                    <CiLocationOn className='me-1 mt-1' />
                                    {profile.location}
                                </span>
                            </Tooltip>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><CiMail className='me-1 mt-1' /> {profile.official_mail}</span>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><FaFontAwesomeFlag className='me-1 mt-1' /> Founded,2000</span>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><FaNetworkWired className='me-1 mt-1' />Advertising&Marketing</span>

                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}><span className='text-black font-semibold'> CIN: </span>{profile.CIN}</span>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}> <span className='text-black font-semibold'> PAN:</span>{profile.PAN}</span>
                        </div>
                    </div>
                    <div className={`${isDark ? 'bg-slate-700' : 'bg-white'} h-full rounded-lg w-full`}>
                        <div className='p-2'>
                            <h1 className='text-2xl font-bold'>Other details</h1>
                        </div>
                        <ul className='p-3 space-y-4'>
                            <li
                                onClick={() => setOpenReview(!openReview)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'>
                                Reviews
                                {openReview ?
                                    <FaChevronUp
                                        className='cursor-pointer'
                                        onClick={() => setOpenReview(!openReview)}
                                    />
                                    :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenReview(!openReview)}
                                    />
                                }
                            </li>
                            {openReview && <div>
                                <Reviews />
                            </div>}

                            <li
                                onClick={() => setOpenVerification(!openVerification)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                            >
                                Verification
                                {openVerification ?
                                    <FaChevronUp
                                        className='cursor-pointer'
                                        onClick={() => setOpenVerification(!openVerification)}
                                    />
                                    :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenVerification(!openVerification)}
                                    />
                                }
                            </li>
                            {openVerification && <div>
                                <YourVerification />
                            </div>}

                            <li
                                onClick={() => setOpenPortfolio(!openPortfolio)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                            >
                                Portfolio & Awards
                                {openPortfolio ?
                                    <FaChevronUp className='cursor-pointer'
                                        onClick={() => setOpenPortfolio(!openPortfolio)}
                                    /> :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenPortfolio(!openPortfolio)}
                                    />}
                            </li>
                            {openPortfolio && <div>
                                <Portfolio />
                            </div>}

                            <li
                                onClick={() => setOpenContact(!openContact)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                            >
                                Contact Information
                                {openContact ?
                                    <FaChevronUp
                                        className='cursor-pointer'
                                        onClick={() => setOpenContact(!openContact)}
                                    /> :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenContact(!openContact)}
                                    />}
                            </li>
                            {openContact && <div>
                                <ContactInfo />
                            </div>}

                        </ul>
                    </div>
                </div>

            </div>}
        </div>
    )
}

export default CompanyProfile