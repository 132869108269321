import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFoundPage() {
    const navigate = useNavigate()
  return (
    <div className='min-h-screen flex flex-col justify-center items-center p-8 bg-opacity-90'>
        <h1 className='text-6xl font-bold'>404</h1>
        <p className='text-xl'>Page Not Found</p>
        <p className='text-gray-600'>The page you are looking for does not exist</p>
        <button className='bg-blue-600 p-2 text-white rounded-lg mt-3' onClick={()=>navigate('/')}>
            Go to Home
        </button>
    </div>
  )
}

export default NotFoundPage