import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForgotPasswordForm } from '../../../helper/auth/authFormHook'
import { forgotPassword } from '../../../helper/auth/authRequestCall'
import { message } from 'antd'

function ForgotPassword() {
    const { isDark } = useSelector((state) => state.themeState)
    const handleSubmit = (values) => {
        const emailId = values.email
        const new_password = values.newPassword
        forgotPassword(emailId, new_password)
            .then((data) => { 
                if (data && data.status === 200) {
                    formik.resetForm()
                    message.success("Password changed successfully")
                  }else{
                    message.error("registration failed")
                  }
            })
            .catch((err) => { console.error("error occurd:", err) })
    }
    const formik = useForgotPasswordForm(handleSubmit)
    return (
        <div className='flex justify-center items-center min-h-screen bg-blue-950'>
            <div className={`w-full justify-center max-w-xs md:max-w-sm p-8 space-y-6 ${!isDark ? 'bg-blue-500' : 'bg-slate-500 '} rounded `}>
                <h1 className='text-black text-center text-2xl font-bold'>Reset Password</h1>
                <form onSubmit={formik.handleSubmit} className='flex flex-col ' >
                    <input
                        placeholder='enter your email'
                        id='email'
                        className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.email && formik.errors.email ? 'border-2 border-red-600' : ' border-gray-400'}`}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    {
                        formik.touched.email && formik.errors.email ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.email}
                            </div> : null
                    }
                    <input
                        placeholder='enter new password'
                        type='password'
                        id='newPassword'
                        className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.newPassword && formik.errors.newPassword ? 'border-2 border-red-600' : ' border-gray-400'}`}
                        onChange={formik.handleChange}
                        value={formik.values.newPassword}
                    />
                    {
                        formik.touched.newPassword && formik.errors.newPassword ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.newPassword}
                            </div> : null
                    }
                    <div className='flex justify-between'>
                        <p className='px-2 me-5 mt-2'>Back to <Link to={'/login'} className='font-bold underline'>signIn</Link>?</p>
                        <input
                            type='submit'
                            className='btn max-w-md mt-2 w-28 ml-5'
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword