import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function PortfolioDetail({openDetail,handleToClose,refImage,description,title}) {
    return (
        <Dialog open={openDetail} onClose={handleToClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <img src={refImage} alt={title}/>
                <DialogContent>
                    {description}
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToClose}
                    color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PortfolioDetail