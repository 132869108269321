import Home from "./Home";
import TermsAndService from "../src/views/pages/public/TermsAndService";
import Login from "../src/views/pages/auth/Login";
import Register from "../src/views/pages/auth/Register";
import CourseDetails from "../src/views/pages/public/CourseDetails";
import SetupOdc from "../src/views/pages/public/SetupOdc";
import AdminDashboard from '../src/views/pages/private/admin/AdminDashboard'
// import Portfolio from "../src/views/pages/private/serviceProvider/Portfolio";
import Reviews from "../src/views/pages/private/serviceProvider/Reviews";
import CreatePortfolio from "../src/views/pages/private/serviceProvider/CreatePortfolio";
import RegisterDetail from "./views/pages/private/organization/RegisterDetail";
import ServicEProviderRegister from "../src/views/pages/private/serviceProvider/ServicEProviderRegister";
import CompanyRegistration from "./views/pages/private/CompanyRegistration";
import CompanyProfile from "./views/pages/private/organization/CompanyProfile";
import CompanyMoreDetail from "./views/pages/private/CompanyMoreDetail";
import SearchResult from "./views/pages/SearchResult";
import ServiceProviderList from "./views/pages/private/ServiceProviderList";
import OrgDetailsConfirm from "./views/pages/private/organization/OrgDetailsConfirm";
import React from "react";
import Dashboard from "./views/pages/private/Dashboard";
import ForgotPassword from "./views/pages/auth/ForgotPassword";

// const Dashboard=React.lazy(()=>import('../src/views/pages/private/Dashboard'))
export const publicRoutes = [
    {path:'/',name:"home",component:Home,exact:true},
    {path:'/login',name:"login",component:Login,exact:true},
    {path:'/register',name:"register",component:Register,exact:true},
    {path:'/terms-and-service',name:"terms",component:TermsAndService,exact:true},
    {path:'/industry/:name',name:"course details",component:CourseDetails,exact:true},
    {path:'/setup-odc-india',name:"ODC india",component:SetupOdc,exact:true},
    {path:'/search/result',name:"Search result",component:SearchResult,exact:true},
    {path:'/login/forgot-password',name:"forgot password",component:ForgotPassword,exact:true},
]

export const private_routes = [
    {path:'/user/dashboard',name:"Dashboard",component:Dashboard,exact:true},
    // {path:'/service-provider/portfolio',name:"Service Provider portfolio",component:Portfolio,exact:true},
    {path:'/service-provider/user-reviews',name:"Service Provider reviews",component:Reviews,exact:true},
    {path:'/service-provider/register',name:"Service Provider register",component:ServicEProviderRegister,exact:true},
    {path:'/service-provider/create-portfolio',name:"Create portfolio",component:CreatePortfolio,exact:true},
    {path:'/register-company',name:"Register",component:CompanyRegistration,exact:true},
    {path:'/register-company/company-detail',name:"Create company profile",component:RegisterDetail,exact:true},
    {path:'/register-company/details-confirm',name:"confirm details",component:OrgDetailsConfirm,exact:true},
    {path:'/register-company/input/more-detail',name:"Add more detail",component:CompanyMoreDetail,exact:true},
    {path:'/comapany/profile/:org_id',name:"company profile",component:CompanyProfile,exact:true},
    {path:'/service-provider/list',name:"Service Provider list",component:ServiceProviderList,exact:true},
]
export const admin_routes = [
    {path:'/admin/dashboard',name:"Admin Dashboard",component:AdminDashboard,exact:true},
]