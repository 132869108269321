import React from 'react'
import { useSelector } from 'react-redux'

function YourVerification() {
  const { isDark } = useSelector((state) => state.themeState)

  return (
    <div className='h-screen'>
        <div className={`flex ${isDark ? 'bg-black' : 'bg-white' } h-full justify-center items-center`}>
             <h1>Your verification</h1>
        </div>
    </div>
  )
}

export default YourVerification