import React from 'react'

function RatingStar({onClick,filled}) {
    return (
        <svg
        className={`w-4 h-4 cursor-pointer ${
          filled ? "text-yellow-500" : "text-gray-300"
        }`}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.917c.969 0 1.371 1.24.588 1.81l-3.98 2.89a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.538 1.118l-3.98-2.89a1 1 0 00-1.175 0l-3.98 2.89c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.364-1.118L2.971 9.1c-.783-.57-.38-1.81.588-1.81h4.917a1 1 0 00.95-.69L9.049 2.927z"
        />
      </svg>
    )
}

export default RatingStar