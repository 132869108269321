import React from 'react'
import { ImTelegram } from 'react-icons/im'
import { Link, useNavigate } from 'react-router-dom'
import { CiLogout } from "react-icons/ci";
import { useDispatch } from 'react-redux';
import { authenticated } from '../../../src/store/slices/authSlice';

function ProfileDropdown({profileDropdown,items,handleOpenProfile}) {
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const handleLogout=()=>{
        // dispatch(authenticated())
        localStorage.clear()
        window.location.reload(false)
        window.location.href='/'
    }
    return (
        <div>
             {profileDropdown && (
                <ul className='absolute right-3 mt-2 w-24 bg-blue-700 rounded-md shadow-lg py-1 px-2 z-10'>
                    {items?.map((item, index) => (
                        <Link to={item.path} key={index}>
                            <button onClick={()=>navigate(item.path)}><li className='flex mt-2 text-white'> {item.type==="route" && item.name }</li></button> 
                            <button onClick={handleOpenProfile}><li className='mt-2 text-white'>{item.type==="action" && item.action }</li></button>
                        </Link>
                    ))}
                    <button onClick={()=>handleLogout()}><li className='flex mt-2 text-red-800'><CiLogout className='mt-1 me-1'/> Logout</li></button>
                </ul>
            )}
        </div>
    )
}

export default ProfileDropdown