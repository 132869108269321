import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function ActivityChart({ items }) {
  const { isDark } = useSelector((state) => state.themeState)

  return (
    <div className={`h-52 ${isDark ? 'bg-black' : 'bg-white' } p-4 rounded-sm border-gray-200 flex flex-col flex-1`}>
      <div className='w-full mt-3 flex-1'>
        <ResponsiveContainer width="100%" height='99%'>
          <BarChart
            width={500}
            height={300}
            data={items}
            margin={{
              top: 20,
              right: 10,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' vertical={false} />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='data1' fill='#0ea5e9' />
            <Bar dataKey='data2' fill='#ea580c' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ActivityChart;
