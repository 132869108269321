import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filterTogle ,sidebarToggle} from '../store/slices/sidebarSlice';

function AppSidebar({ menuItems }) {
    const location = useLocation();
    const [active, setActive] = useState(location.pathname);
    const { isSidebar,isFilter } = useSelector((state) => state.sidebarState)
    const { isDark } = useSelector((state) => state.themeState)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        setActive(location.pathname);
    }, [location.pathname]);


    const handleRouteActive=(path)=>{
        setActive(path)
    }

    useEffect(()=>{
        if (location.pathname === "/service-provider/list") {
            dispatch(filterTogle(true))
            dispatch(sidebarToggle(false))
          }
    },[location,dispatch])
    return (
        <aside className={`${isSidebar ? "hidden":"block"} sticky top-0 w-72 h-screen ${isDark ? 'bg-slate-900' : 'bg-blue-950'} border-r-2 border-gray-500`}>     
            <div className='flex flex-col justify-between mt-6'>
            <ul className='space-y-5 py-2'>
                {menuItems && menuItems.map((item) => (
                    <li key={item.name}>
                        <Link
                            to={item.to}
                            onClick={() => handleRouteActive(item.to)}
                            className={`flex p-2 font-medium text-white mt-3 ${active === item.to ? 'bg-blue-700' : ''
                                }`}
                        >
                         <span className='me-3 mt-1'>{item.icon}</span>
                         {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
            {/* <div className='ml-5 border-t p-2 bottom-0 mt-56'>
                <button className='text-white flex hover:text-red-700'>
                  <BiLogOutCircle className='mt-1 mb-2 me-2' size={20}/> <span className='mt-0.5'>Logout</span> 
                </button>
            </div> */}
            </div>
        </aside>
    )
}

export default AppSidebar