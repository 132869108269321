import { Route, Routes, useLocation } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import {publicRoutes, private_routes } from '../routes';
import NotFoundPage from '../views/pages/NotFoundPage';
//import { useLocation } from 'react-router-dom';
import PublicLayout from '../views/layout/PublicLayout';
import PrivateLayout from '../views/layout/PrivateLayout';
import { jwtDecode } from 'jwt-decode';

function AppView() {
  const location = useLocation();
  const defaultSize = 10
  const [fontSize, setFontSize] = useState(defaultSize)
  const [active, setActive] = useState(location.pathname);
  const[auth,setAuth]=useState(false)
  const setDefaultFont = () => {
    setFontSize(defaultSize)
  }

  const increaseFont = () => {
    setFontSize((prevFont) => prevFont + 2)
  }

  const decreaseFont = () => {
    setFontSize((prevFont) => prevFont - 2)
  }
 
  const fetchToken=()=>{
    const token=localStorage.getItem('authToken')
    if(token){
      setAuth(true)
      // dispatch(setUserDetails(decoded))
      console.log("decoded token:",jwtDecode(token))
    }else{
      setAuth(false)
    }
  }

  useEffect(()=>{
    fetchToken()
  },[])
  return (
    <div>
      {/* <Suspense fallback={<h1 className='text-center'>loading...</h1>}></Suspense> */}
      <Routes>
        {publicRoutes.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              <PublicLayout setDefaultFont={setDefaultFont}
                increaseFont={increaseFont}
                decreaseFont={decreaseFont}
                auth={auth}
                fontSize={fontSize}>
                <routes.component fontSize={fontSize} />
              </PublicLayout>
            }
          />
        ))}
        {auth && private_routes.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              <PrivateLayout setDefaultFont={setDefaultFont}
                increaseFont={increaseFont}
                decreaseFont={decreaseFont}
                auth={auth}
                active={active}
                setActive={setActive}
                fontSize={fontSize}>
                <routes.component fontSize={fontSize} />
              </PrivateLayout>
            }
          />
        ))}
        <Route path="*" element={
          <PublicLayout>
            <NotFoundPage />
          </PublicLayout>
          } />
      </Routes>
    </div>
  );
}
export default AppView;
