import React from 'react'
import { useSelector } from 'react-redux'
import { FaLinkedin, FaFacebookSquare, FaPhoneAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineLink } from "react-icons/ai";
import { IoMailSharp } from "react-icons/io5";
import ServiceProviderStep from '../../../../utils/ServiceProviderStep';
import { FaChevronRight } from "react-icons/fa";
import { service_provider_categories } from '../../../../utils/tempJson/_optionsJson';

function ServicEProviderRegister() {
  const { isDark } = useSelector((state) => state.themeState)
  
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
        <h1 className='text-center text-gray-600'>ENTER THE DETAILS BELOW</h1>
        {/* <ServiceProviderStep /> */}
        <form
          className='flex flex-col space-y-3 px-5 py-8'
        >
          {/* <div className='flex flex-col space-y-3 px-5 py-8 border border-gray-300 rounded-md'>
            <div className='flex gap-2 '>
              <div className='relative w-full '>
                <FaPhoneAlt className='absolute top-3 left-3' />
                <input
                  type='number'
                  placeholder='Enter your mobile number'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
              <div className='relative w-full '>
                <IoMailSharp className='absolute top-3 left-3' />
                <input
                  type='email'
                  placeholder='Enter Email'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
            </div>
            <select
              name='Service-categories'
              className='w-full p-2 focus:outline-none border border-gray-600 rounded'
            >
              {service_provider_categories.map((options, index) => (
                <option className='cursor-pointer' key={index} value={options.value}>
                  {options.label}
                </option>
              ))}
            </select>
            <div className='grid grid-cols-2 gap-2'>
              <div className='relative'>
                <FaLinkedin className='absolute left-3 top-3 text-slate-700' />
                <input
                  type='url'
                  name='linkdin'
                  placeholder='LinkedIn profile'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
              <div className='relative'>
                <FaInstagram className='absolute left-3 top-3 text-slate-700' />
                <input
                  type='url'
                  name='instagram'
                  placeholder='Instagram profile'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
              <div className='relative'>
                <FaFacebookSquare className='absolute left-3 top-3 text-slate-700' />
                <input
                  type='url'
                  name='facebook'
                  placeholder='Facebook profile'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
              <div className='relative'>
                <AiOutlineLink className='absolute left-3 top-3 text-slate-700' />
                <input
                  type='url'
                  name='website'
                  placeholder='Your website'
                  className='w-full p-2 focus:outline-none border border-gray-600 rounded pl-10 placeholder-slate-700'
                />
              </div>
            </div>
            <div className='w-full flex justify-end items-end me-2'>
            <button type='submit' className='flex bg-blue-900 text-white p-2 max-w-64 rounded-full'>
              Next <FaChevronRight className='mt-1 ml-2'/>
            </button>
            </div>
          </div> */}
          {/* <h1>Portfolio:</h1> */}
          <input
                    type='text'
                    className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                    placeholder='Enter title'
                />

                <textarea
                   className='p-3 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                   placeholder='Provide description'
                   rows='5'
                />
                <input
                type='file'
                className='max-w-md w-52 text-white'
                />
                <div className='flex justify-center items-center'>
                <input
                type='submit'
                className='btn max-w-md w-52'
                />
                </div>
        </form>

      </div>
    </div>
  )
}

export default ServicEProviderRegister