import React from 'react'
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from 'react-redux';

function AboutProduct({ fontSize }) {
  const { isDark } = useSelector((state) => state.themeState)
  return (
    <div className={`min-h-screen flex flex-col p-8 ${!isDark ? 'bg-blue-950' : 'dark-mode'}`} id='about-product'>
      <h1 className='text-3xl md:text-6xl px-7 text-white'>About product</h1>
      <div className='flex flex-col md:flex-row gap-8 p-14 mt-5 ml-2 md:ml-11'>
        <div className={`p-4 bg-gradient-to-r ${!isDark ? 'from-purple-400 via-blue-400 to-green-300' : 'from-slate-600 via-slate-700 to-slate-900'}py-8 md:px-10 rounded-lg w-full md:w-1/3`}>
          <h1 className={`${!isDark ? 'text-black' : 'text-white'} font-bold text-xl`}>Lead ​Generation</h1>
          <p className='mt-4 md:p-3 font-semibold' style={{ fontSize: `${fontSize + 7}px` }}>The perfect push to<br /> ​kickstart your adventure.</p>
          <div className='flex justify-end mt-10'>
            <span className='flex-end bottom-0 me-2 cursor-pointer'><FaArrowRight size={30} /></span>
          </div>
        </div>
        <div className={`p-4 bg-gradient-to-r ${!isDark ? 'from-blue-400 via-white to-purple-500' : 'from-slate-600 to-slate-700'} py-8 md:px-10 rounded-lg w-full md:w-1/3`}>
          <h1 className={`${!isDark ? 'text-black' : 'text-white'} font-bold text-xl`}>Talent ​Acquisition</h1>
          <p className='mt-4 md:p-3 font-semibold' style={{ fontSize: `${fontSize + 7}px` }}>Matching exceptional <br />talent ​with key roles<br /> to drive ​growth.</p>
          <div className='flex justify-end mt-4'>
            <span className='flex-end bottom-0 me-2 cursor-pointer'><FaArrowRight size={30} /></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutProduct
