import { createSlice } from "@reduxjs/toolkit";

const companyRegisterSlice = createSlice({
    name: 'org-register',
    initialState: {
        orgInfo: localStorage.getItem('orgInfo') ? JSON.parse(localStorage.getItem('orgInfo')):{},
    },
    reducers: {
        orgDetailsSave(state, action) {
            localStorage.setItem('orgInfo',JSON.stringify(action.payload))
            return{
                ...state,
                orgInfo:action.payload
            }
        }
    }
});
const { actions, reducer } = companyRegisterSlice
export const { orgDetailsSave } = actions
export default reducer