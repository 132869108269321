import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MdAssignmentAdd } from "react-icons/md";
import ActivityChart from '../../../components/private/ActivityChart';
import verificationImg from '../../../assets/verification.png'
import { message, Tooltip } from 'antd'
import { HiEye } from "react-icons/hi2";
import { Base_url } from '../../../utils/constant';
import { Link } from 'react-router-dom';
import { organizationImages } from '../../../helper/organization/orgRequestCall';
import {jwtDecode} from 'jwt-decode'
import { setUserDetails } from '../../../store/slices/authSlice';
import Slider from 'react-slick';


function Dashboard() {
  const { isDark } = useSelector((state) => state.themeState)
  const {authenthurized,user}=useSelector((state)=>state.authenticationState)
  const navgate = useNavigate()
  const dispatch=useDispatch()
  const [companyDetail, setComapnyDetail] = useState(null)
  const[companyLogos,setCompanyLogos]=useState([])
  // const detail=localStorage.getItem('token')
  // const decode=jwtDecode(detail)
  // console.log(decode)

  const handleCompanyDetail = (item) => {
    if (item.verified) {
      setComapnyDetail(item)
      console.log(companyDetail)
    } else {
      message.warning("Your organization under verification please wait until its done...")
    }
  }

  const getLogos=async()=>{
    await organizationImages()
    .then((res)=>{
      if(res.status===200){
          setCompanyLogos(res.data)
      }
    })
    .catch((err)=>{
      console.error(err)
    })
  }
  
  useEffect(()=>{
    getLogos()
    // dispatch(setUserDetails(decode))
  },[])
  
  // console.log(user)
  return (
    <div
      className={`flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
    >
      <div className={`w-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-slate-100' : 'bg-black'} space-y-6 rounded-lg py-2 mt-4 px-2`}>
        <div className='flex justify-between w-full mt-1'>
          <div></div>
          <button className='flex bg-green-600 p-2 me-4 mt-6 rounded-lg text-white' onClick={() => navgate('/register-company')}>Register your company <MdAssignmentAdd className='mt-1 ml-2' /></button>
        </div>
        <div className={`w-full mt-1 max-w-5xl space-y-6 rounded-lg px-6 p-2`} >
          <div className="flex flex-wrap gap-2 p-2">
            {companyLogos.map((item, index) => (
           
              <div className='flex flex-row gap-2 p-2' key={index}>
                <img
                  src={item.verified ? `${Base_url}/${item.logo}` : verificationImg}
                  alt='company'
                  className={`relative w-28 h-28 rounded-full bg-white cursor-pointer`}
                  onClick={() => handleCompanyDetail(item)}
                />
                <span className='absolute text-blue-700 hover:text-blue-950 font-bold  text-center ml-20 cursor-pointer'>
                  <Tooltip placement='top' title="view details">
                    <Link to={`/comapany/profile/${item.org_id}`}><HiEye size={20} /></Link> 
                  </Tooltip>
                </span>
                {/* {!item.verified && <span className='absolute text-black font-bold mt-7 text-center ml-3 cursor-pointer'>Under<br /> verification</span>} */}
              </div>
              
              ))}
          </div>
        </div>
        <div className={`w-full max-w-7xl justify-center ${!isDark ? 'bg-slate-200' : 'bg-slate-500 '} rounded-lg h-80 px-6 py-2`}>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Activity</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
                {companyDetail?.charts && (
                  <ActivityChart items={companyDetail.charts} />
                )}
              </div>
            </div>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Chart</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Dashboard