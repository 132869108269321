import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function ServiceDropdown({setMobileDropdown,mobileDropdown,togleMenu,menu,setDropdown,dropDown}) {
    const location = useLocation()
    return (
        <div>
                <li className='relative me-4 text-white cursor-pointer mt-2'>
                    <button
                        className='flex items-center lg:hidden md:hidden'
                        onClick={() => setMobileDropdown(!mobileDropdown)}
                    >
                        Services
                        {mobileDropdown ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </button>
                    {mobileDropdown && <ul className='ml-2 md:hidden'>
                        <li className='mt-2'><a href='#inkqube' onClick={() => togleMenu(!menu)}>The Inkqubee ​Academy</a></li>
                        <li className='mt-2'><a href='#leadgen'>Lead ​Generation</a></li>
                        <li className='mt-2'>Talent ​Acquisition</li>
                        <Link to={"/setup-odc-india"}><li className='mt-2'>Setup ODC in India</li></Link>
                    </ul>}
                    <button
                        onMouseEnter={() => setDropdown(true)}
                        onMouseLeave={() => setDropdown(false)}
                        className='hidden md:block'
                    >
                        services
                    </button>
                    {
                        dropDown && (
                            <ul
                                className='absolute justify-center right-0 w-[300px] bg-blue-800 text-black shadow-lg rounded-lg'
                                onMouseEnter={() => setDropdown(true)}
                                onMouseLeave={() => setDropdown(false)}
                            >
                                <li className="px-4 py-2 text-white hover:bg-black"><a href='#inkqube'>The Inkqubee ​Academy</a></li>
                                <li className="px-4 py-2 text-white hover:bg-black"><a href='#leadgen'>Lead ​Generation</a></li>
                                <li className="px-4 py-2 text-white hover:bg-black">Talent ​Acquisition</li>
                                <Link to={"/setup-odc-india"}><li className="px-4 py-2 text-white hover:bg-black">Setup ODC in India</li></Link>
                            </ul>
                        )
                    }
                </li>
        </div>
    )
}

export default ServiceDropdown