import { combineReducers, configureStore } from "@reduxjs/toolkit";
import  {thunk}  from "redux-thunk";
import themeReducer from '../src/store/slices/themeSlice'
import sidebarReducer from "../src/store/slices/sidebarSlice";
import companyRegisterReducer from "../src/store/slices/companyRegisterSlice"
import authReducer from "../src/store/slices/authSlice"
const reducer=combineReducers({
   themeState:themeReducer,
   sidebarState:sidebarReducer,
   orgRegiterState:companyRegisterReducer,
   authenticationState:authReducer
})
const store=configureStore({
    reducer,
    //middleware:[thunk]
})

export default store