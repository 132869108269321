import React from 'react'
import Header from '../../components/public/Header'
import '../../App.css';
import Footer from '../../components/public/Footer';
import { footer_excludes } from '../../utils/tempJson/_footer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicLayout({setDefaultFont,
    increaseFont,
    decreaseFont,
    fontSize,children,auth}) {
   const location = useLocation()
   const {authenthurized}=useSelector((state)=>state.authenticationState)
    
    return (
        <div>
            <div className="flex flex-col min-h-screen">
                <header className='fixed top-0 left-0 w-full z-50'>
                    <Header
                        setDefaultFont={setDefaultFont}
                        increaseFont={increaseFont}
                        decreaseFont={decreaseFont}
                        fontSize={fontSize}
                        auth={auth}
                    />
                </header>
                <div className="flex-1">
                    {children}
                </div>
                {!footer_excludes.includes(location.pathname) && (
                    <footer className="w-full text-center mt-auto">
                        <Footer />
                    </footer>
                )}
            </div>
        </div>
    )
}

export default PublicLayout
