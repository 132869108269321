import { Base_url } from "../../utils/constant";
import Axios from "axios"

const token=localStorage.getItem('authToken')
export const organizationImages = ()=>{
    return Axios({
        method:"GET",
        url:`${Base_url}/api/organization/organization-list/`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const organizationRegister = (
    name,
    brand_name,
    CIN,
    PAN,
    location,
    official_mail,
    logo,
    No_of_employees,
    registered_document,
    service_or_products
)=>{
    let formData=new FormData();
    if(logo){
        logo.forEach((file)=>{
            formData.append("logo",file)
        })
    }
    
    formData.append('name',name);
    formData.append('brand_name',brand_name);
    formData.append('CIN',CIN);
    formData.append('PAN',PAN)
    formData.append('location',location);
    formData.append('official_mail',official_mail);
    formData.append('logo',logo);
    formData.append('registered_document',registered_document)
    formData.append('No_of_employees',No_of_employees)
    formData.append('service_or_products',service_or_products)
    return Axios({
        method:"POST",
        url:`${Base_url}/api/organization/register-organizaton/`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        data:formData
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const companyProfile = (organization_id)=>{
    return Axios({
        method:"GET",
        url:`${Base_url}/api/organization/view-organization/${organization_id}`,
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}



