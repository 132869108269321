import React from 'react'
import InkqubeAcadamy from './components/public/InkqubeAcadamy'
import AboutProduct from './components/AboutProduct';
import LastSection from './components/public/LastSection';
import Hero from './components/public/Hero';

function Home({ fontSize }) {
  return (
    <div className='min-h-screen justify-center flex flex-col'>
      <Hero fontSize={fontSize}/>
      <AboutProduct fontSize={fontSize} />
      <InkqubeAcadamy fontSize={fontSize} />
      <LastSection fontSize={fontSize} />
    </div>

  )
}

export default Home
