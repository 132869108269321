import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { sp_portfolio_items } from '../../../utils/tempJson/_tempItems'
import { useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import PortfolioDetail from '../../../utils/Modal/PortfolioDetail';

function Portfolio() {
  const { isDark } = useSelector((state) => state.themeState)
  const [openDetail, setOpen] = useState(false);
  const [detail, setDetail] = useState({

  })
  const navigate = useNavigate()
  const handleOpen = (data) => {
    setOpen(true);
    setDetail(data)
  };

  const handleToClose = () => {
    setOpen(false);
    setDetail({})
  };
  return (
    <div className={`flex flex-col h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3`}>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 p-6 py-14 bg-slate-300 w-full max-w-7xl mt-20'>
          {sp_portfolio_items.map((item, index) => {
            return (
              <div key={index} className='ml-2 mt-10'>
                <img src={item.image} className='w-60 h-32' />
                <div className='flex justify-between'>
                  <h1>{item.title}</h1>
                  <span className='mt-2 cursor-pointer'><FaEye /></span>
                </div>
              </div>
            )
          })}
       
        <PortfolioDetail
          openDetail={openDetail}
          handleToClose={() => setOpen(false)}
          title={detail && detail.title}
          refImage={detail && detail.image}
          description={detail && detail.detail}
        />
      </div>

    </div>
  )
}

export default Portfolio