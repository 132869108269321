import React, { Fragment } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { loginSchema } from '../../../validation'
import { message } from 'antd'
import { authenticated, setUserDetails } from '../../../store/slices/authSlice'
import { useLoginForm } from '../../../helper/auth/authFormHook'
import {jwtDecode} from 'jwt-decode'
import { signinUser } from '../../../helper/auth/authRequestCall'

function Login({auth,setAuth}) {
  const {isDark}=useSelector((state)=>state.themeState)
  // const {authenthurized}=useSelector((state)=>state.authenticationState)
  // const navigate = useNavigate()
  const dispatch=useDispatch()



  const handleSignIn=(values)=>{
    const username=values.userName.toLowerCase()
    const password = values.password
    signinUser(username,password)
    .then((data)=>{
      if(data && data.status === 200){
        const token=data.data.data.token
        const decoded=jwtDecode(token)
        localStorage.setItem('authToken',token)
        message.success("login success")
        window.location.href='/user/dashboard'
        console.log(decoded)
      }else{
        message.error("invalid credentials or account not activated")
      }
    })
    .catch((err)=>{
      console.error("error occured",err)
    })
  }
  

  const formik=useLoginForm(handleSignIn)
  return (
    <Fragment>
    <div className={`flex flex-col justify-center items-center min-h-screen ${!isDark? 'bg-blue-950':'dark-mode'}`}>
      <div className={`w-full justify-center max-w-xs md:max-w-sm p-8 space-y-6 ${!isDark? 'bg-blue-500':'bg-slate-500 '} rounded `}>
        <h1 className='text-black text-2xl font-bold text-center mb-4'>SignIn</h1>
        <form className='flex flex-col px-2' onSubmit={formik.handleSubmit}>
          <input
            type='email'
            id='userName'
            placeholder='enter your email'
            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl ${formik.touched.userName && formik.errors.userName ? 'border-2 border-red-600' : ' border-gray-400'}`}
            onChange={formik.handleChange}
            value={formik.values.userName}
          />
          {
            formik.touched.userName && formik.errors.userName ? 
            <div className='text-red-700 ml-2 mt-1'>
              {formik.errors.userName}
            </div> : null
          }
          <input
            type='password'
            id='password'
            placeholder='enter your password'
            className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-3xl mt-2 ${formik.touched.password && formik.errors.password ? 'border-2 border-red-600' : ' border-gray-400'}`}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {
            formik.touched.password && formik.errors.password ? 
            <div className='text-red-700 ml-2 mt-1'>
              {formik.errors.password}
            </div> : null
          }
          <div className="flex justify-between mt-2">
          <p className='px-2'>For Register <Link to='/register' className='text-black font-bold underline'> Signup</Link></p>
          <Link to={'/login/forgot-password'}>Forgot password?</Link>
          </div>
          <input type='submit' value="SignIn" className='btn mt-2' />
        </form>
      </div>
    </div>
    </Fragment>
  )
}

export default Login
