export const servicesOptions = [
    { value: '', label: 'Select Services/Products' },
    { value: 'Advertising & Marketing', label: 'Advertising & Marketing' },
    { value: 'Development', label: 'FemaDevelopmentle' },
    { value: 'Design & Production', label: 'Design & Production' },
    { value: 'IT Services', label: 'IT Services' },
    { value: 'Buisness Services', label: 'Buisness Services' },
    { value: 'other', label: 'Other' },
];

export const service_filter_Options = [
    { value: '', label: 'Relevance' },
    { value: 'Most recent', label: 'Most recent' },
    { value: 'Rating:hign to low', label: 'Rating:hign to low' },
    { value: 'Rating:low to high', label: 'Rating:low to high' }
  ];

export const service_provider_categories = [
    { value: '', label: 'Select Your Service Category' },
    { value: 'Advertising & Marketing', label: 'Advertising & Marketing' },
    { value: 'Development', label: 'FemaDevelopmentle' },
    { value: 'Design & Production', label: 'Design & Production' },
    { value: 'IT Services', label: 'IT Services' },
    { value: 'Buisness Services', label: 'Buisness Services' },
    { value: 'other', label: 'Other' },
  ];