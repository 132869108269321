import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

function SetupOdc({fontSize}) {
    const {isDark}=useSelector((state)=>state.themeState)
    const handleSubmit=(e)=>{
       e.preventDefault()
    }
    return (
        <Fragment>
            <div className={`min-h-screen flex flex-col justify-center items-center p-8 ${!isDark? 'bg-blue-950':'dark-mode'} bg-opacity-90`}>
                <div className={`w-full max-w-2xl justify-center ${!isDark? 'bg-blue-500':'bg-slate-500 '} space-y-6 rounded-lg mt-16`}>
                    <h1 className='text-black text-center py-2' style={{fontSize:`${fontSize+9}px`}}>Enter the details below</h1>
                    <form 
                    className='flex flex-col space-y-4 px-5 py-3'
                    onSubmit={()=>handleSubmit()}
                    >
                        <input
                            type='text'
                            className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                            placeholder='Enter Your Name'
                        />

                        <input
                            type='number'
                            maxLength={10}
                            className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                            placeholder='Enter Your Mobile Number'
                        />

                        <input
                            type='text'
                            placeholder='Enter Your Company Name'
                            className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                         />

                        <input  
                            type='text'
                            placeholder='Enter Your Company Headquarters'
                            className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                        />

                        <textarea
                           className='p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg'
                           placeholder='Type Your Requirements here'
                           maxLength={500}
                           rows='5'
                        />
                        <div className='flex justify-center items-center'>
                        <input
                        type='submit'
                        className='btn max-w-md w-52'
                        />
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default SetupOdc