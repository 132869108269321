import  Axios  from "axios";

const token=localStorage.getItem('authToken')
export const signinUser=(username,password)=>{
    return Axios({
        method:"POST",
        url:'/api/auth/login',
        body:JSON.stringify(),
        data:{
            username,
            password
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const signupUser=(fullName,emailId,password)=>{
    return Axios({
        method:"POST",
        url:'/api/auth/register',
        body:JSON.stringify(),
        data:{
            fullName,
            emailId,
            password
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const forgotPassword=(emailId,newPassword)=>{
    return Axios({
        method:"POST",
        url:'/api/auth/reset-password',
        body:JSON.stringify(),
        data:{
            emailId,
            newPassword
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getUserProfile=(id)=>{
    return Axios({
        method:'GET',
        url:`/api/user/${id}`,
        headers:{
            Accept:'application/json',
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err
    })
}