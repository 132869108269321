import './App.css';
import { BrowserRouter} from 'react-router-dom';
import AppView from './components/AppView';
// import "bootstrap/dist/css/bootstrap.min.css"


function App() {
  return (
  <BrowserRouter>
     <AppView/>
  </BrowserRouter> 
  );
}
export default App;
