import React from 'react'
import { inkqubee_acadamy } from '../../utils/tempJson/_tempItems'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from 'react-redux';

function InkqubeAcadamy({ fontSize }) {
  const {isDark}=useSelector((state)=>state.themeState)
  return (
    <section className={`min-h-screen flex flex-col p-5 md:p-8 ${!isDark?'bg-gradient-to-r from-white via-blue-400 to-purple-500':'dark-mode'}`} id='inkqube'>
      <h1 className='px-7 py-7 font-bold' style={{fontSize:`${fontSize+7}px`}}>Learn from the ​experts</h1>
      <p className='px-7 font-semibold' style={{fontSize:`${fontSize+6}px`}}>
        Gain practical skills from courses created by ​industry experts,tailored for individuals looking ​to advance,<br/> switch career paths, or enhance ​their skill set.
      </p>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-8 px-1 md:p-14 mt-5 ml-11 rounded-lg'>
        {inkqubee_acadamy.map((course, index) => (
          <div key={index} className={`flex flex-col p-4 ${!isDark?'bg-blue-700':'bg-slate-600' } justify-center items-center text-center rounded-lg shadow-xl transform transition duration-300 hover:scale-105`}>
            <img src={course.icon} alt='image' className='w-32 h-32' />
            <div className='mt-6'>
              <h1 className='text-white font-bold text-xl mt-2'>{course.name}</h1>
              <Link to={`/industry/${course.name}`}>
              <div className='flex justify-center items-center'>
                <span className='flex flex-row justify-between text-white bottom-0 me-2 cursor-pointer mt-3'>
                  explore
                </span>
                 <FaArrowRight className='mt-4' color="white" />
              </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default InkqubeAcadamy
