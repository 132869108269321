import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        isSidebar: false,
        isFilter: false
    },
    reducers: {
        sidebarToggle(state, action) {
            state.isSidebar = !state.isSidebar
            state.isFilter = false
        },
        filterTogle(state, action) {
            // state.isFilter = action.payload !== undefined ? action.payload : !state.isFilter;
            if(state.isSidebar ){
                state.isFilter = !state.isFilter
            }
        }
    }
});
const { actions, reducer } = sidebarSlice
export const { sidebarToggle ,filterTogle } = actions
export default reducer