import React from 'react'
import { useSelector } from 'react-redux'

function CompanyMoreDetail() {
    const { isDark } = useSelector((state) => state.themeState)
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`w-full max-h-full justify-center ${!isDark ? 'bg-slate-100' : 'bg-slate-500 '} space-y-6 rounded-lg h-full mt-11`}>
                <h1>Enter more detail</h1>
            </div>
        </div>
  )
}

export default CompanyMoreDetail