import { useFormik } from "formik"
import { forgotPasswordSchema, loginSchema, signUpSchema } from "../../validation"

export const useLoginForm=(handleSubmit)=>{
    return useFormik({
        initialValues:{
          userName:"",
          password:""
        },
        validationSchema:loginSchema,
        onSubmit:values=>{
            handleSubmit(values)
        }
      })
}

export const useRegisterForm=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            fullName: "",
            email: "",
            password: "",
            captcha: ""
          },
          validationSchema: signUpSchema,
          onSubmit: values => {
            handleSubmit(values)
          }
      })
}

export const useForgotPasswordForm=(handleSubmit)=>{
  return useFormik({
      initialValues: {
          email: "",
          newPassword: ""
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: values => {
          handleSubmit(values)
        }
    })
}