import React, { useState } from 'react'
import Header from '../../components/public/Header'
import '../../App.css';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/public/Footer';
import AppSidebar from '../../components/AppSidebar';
import PrivateHeader from '../../components/private/PrivateHeader';
import { serviceProviderMenu } from '../../utils/tempJson/_sidebarMenu';
import FilterBar from '../../components/FilterBar';
import { useSelector } from 'react-redux';

function PrivateLayout({ setDefaultFont,
    increaseFont,
    decreaseFont,
    auth,
    fontSize, children }) {
    const location = useLocation()
    const { isSidebar} = useSelector((state) => state.sidebarState)
    return (
        <div className='flex flex-col min-h-screen'>
            <Header auth={auth} />
            
            {/* <div className='px-2'> */}
               {/* <div className='mt-4 mb-9'> */}
                    <PrivateHeader />
            {/* </div> */}
                <div className='flex flex-1'>
                    <AppSidebar menuItems={serviceProviderMenu} />
                     {location.pathname === "/service-provider/list" && isSidebar &&  <FilterBar/>}
                    {children}
                </div>
            {/* </div> */}
            <Footer />
        </div >
    )
}

export default PrivateLayout
